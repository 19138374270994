import classNames from 'classnames'
import React, { useState, useRef, useCallback } from 'react'
import NumberFormat from 'react-number-format'
import { ReactSVG } from 'react-svg'
import Slider from 'src/components/Slider'
import { v4 as uuidv4 } from 'uuid'

import styles from './styles.module.css'

export default ({
  value,
  setValue,
  modalId,
  openedModal,
  setOpenedModal,
  arrow,
  locale,
}) => {
  const sliderModalRef = useRef()
  const sliderTagRef = useRef()
  const [sliderValue, setSliderValue] = useState(value)

  const toggleModal = useCallback(() => {
    openedModal === modalId
      ? setOpenedModal(undefined)
      : setOpenedModal(modalId)
  }, [openedModal, setOpenedModal, modalId])

  const handleOnBlur = useCallback(
    event => {
      if (
        document.body.classList.contains('user-is-tabbing') &&
        !sliderModalRef.current.contains(event.relatedTarget)
      ) {
        toggleModal()
      }
    },
    [toggleModal]
  )

  const handleMouseUp = useCallback(() => {
    setValue(sliderValue)
  }, [setValue, sliderValue])

  return (
    <span className={styles.amountInputContainer} ref={sliderModalRef}>
      <span className={styles.labelContainer}>
        <span
          ref={sliderTagRef}
          className={styles.label}
          role={'button'}
          tabIndex={0}
          onClick={toggleModal}
          onKeyPress={toggleModal}
        >
          <NumberFormat
            value={value}
            displayType={'text'}
            thousandSeparator={' '}
            suffix={'$'}
          />
        </span>
        <span
          role={'menuitem'}
          onBlur={handleOnBlur}
          className={classNames(styles.sliderContainer, {
            [styles.hidden]: openedModal !== modalId,
          })}
        >
          <Slider
            title={''}
            value={sliderValue}
            setValue={setSliderValue}
            min={5000}
            max={1000000}
            suffix={'$'}
            onMouseUp={handleMouseUp}
            setOutsideValue={setValue}
            sliderId={`amountInputSlider${uuidv4()}`}
          />
        </span>
      </span>

      <ReactSVG
        role={'button'}
        onClick={toggleModal}
        src={arrow.publicURL}
        className={styles.arrow}
        beforeInjection={svg => {
          svg.classList.add(styles.logo)
        }}
      />
    </span>
  )
}
