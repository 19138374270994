import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { FormattedDate } from 'react-intl'
import NumberFormat from 'react-number-format'
import { ReactSVG } from 'react-svg'
import Graph from 'src/components/MainPage/CompareInvestments/Graph'
import InvestmentConfigurator from 'src/components/MainPage/CompareInvestments/InvestmentConfigurator'
import fundsComparison from 'src/fundsComparison'
import styles from './styles.module.css'

function computeDate(date = new Date(), yearsBehind = 0) {
  date = new Date(date)
  date.setFullYear(date.getFullYear() - yearsBehind)

  if (yearsBehind !== 0) {
    date.setDate(1)
  }
  return date
}

export default ({
  contentText1,
  contentText2,
  contentText3,
  graphTitle,
  dot,
  mieuxPlanSelection,
  otherSelectionPrefix,
  defaultInvestmentAmount,
  defaultBank,
  defaultInvestmentType,
  startLabel,
  endLabel,
  deleteAction,
  comparisonId,
  shouldHideDeleteButton,
  resultInDate,
  yourInvestments,
  ourSelection,
  yearSuffix,
  yearSuffixPlural,
  comparativeTextPart1,
  comparativeTextPart2,
  comparativeTextPart3,
  comparativeTextPart4,
  rankingTextPart1,
  rankingTextPart2,
  rankingTextPart3,
  rankingNumberSuffix,
  investments,
  arrow,
  updateComparisonsData,
  internalInvestments,
  removeIcon,
  locale,
  firstBank,
}) => {
  const initialBank = defaultBank == null ? firstBank : defaultBank
  const [investmentAmount, setInvestmentAmount] = useState(
    defaultInvestmentAmount
  )
  const [bank, setBank] = useState(initialBank)
  const [investmentType, setInvestmentType] = useState(defaultInvestmentType)

  const getCurrentInvestment = useCallback(
    () =>
      investments.filter(
        investment =>
          investment.company === bank && investment.type === investmentType
      ),
    [bank, investmentType, investments]
  )

  const getCurrentInvestmentPerformanceDate = useCallback(() => {
    return getCurrentInvestment()[0]
      ? getCurrentInvestment()[0].performanceAsOfDate
      : new Date()
  }, [getCurrentInvestment])

  const getInvestmentExternalYield = useCallback((currentInvestment, year) => {
    if (currentInvestment && currentInvestment.compound) {
      return 1 + currentInvestment.compound[year] / 100
    }
    return 1
  }, [])

  const getAllInternalFundYield = useCallback(
    year => {
      const internalFundsYields = {}
      fundsComparison.internalFunds.forEach(internalFund => {
        let totalYield = 0
        Object.entries(internalFund).forEach(fundPart => {
          if (fundPart[0] !== 'name') {
            const currentInvestment = internalInvestments.find(
              investment => investment.code === fundPart[0]
            )
            totalYield +=
              (getInvestmentExternalYield(currentInvestment, year) / 100) *
              fundPart[1]
          }
        })
        internalFundsYields[internalFund.name] = totalYield
      })
      return internalFundsYields
    },
    [getInvestmentExternalYield, internalInvestments]
  )

  const getInvestmentInternalYield = useCallback(
    (currentInvestment, year) => {
      if (
        currentInvestment &&
        fundsComparison.externalFunds[currentInvestment.code]
      ) {
        const internalFundName =
          fundsComparison.externalFunds[currentInvestment.code][0]
        const internalFundsYields = getAllInternalFundYield(year)
        return internalFundsYields[internalFundName] * 100
      }
      return 1
    },
    [getAllInternalFundYield]
  )

  const getInvestmentRanking = useCallback(() => {
    return getCurrentInvestment()[0]
      ? `
    ${
      fundsComparison.externalFunds[getCurrentInvestment()[0].code][1]
    }${rankingNumberSuffix}/${
          fundsComparison.externalFunds[getCurrentInvestment()[0].code][2]
        }`
      : `0/0`
  }, [getCurrentInvestment, rankingNumberSuffix])

  const getInvestmentAmount = useCallback(
    (year, rate, amount) => {
      if (amount) return amount * rate ** year
      else return investmentAmount * rate ** year
    },
    [investmentAmount]
  )

  const getInvestmentAmountInThePast = useCallback(
    (year, rate, amount) => {
      if (amount) return (Math.pow(amount, 1 / year) / rate) ** year
      else return (Math.pow(investmentAmount, 1 / year) / rate) ** year
    },
    [investmentAmount]
  )

  const getMaxCompoundYear = useCallback(() => {
    const currentInvestment = getCurrentInvestment()[0]
    const maxCompound =
      currentInvestment &&
      Object.entries(currentInvestment.compound || {})
        .reverse()
        .find(([_, compound]) => !!compound)

    return maxCompound && maxCompound[0]
  }, [getCurrentInvestment])

  const getInvestmentExternalAmount = useCallback(
    year => {
      const currentInvestment = getCurrentInvestment()[0]
      const externalYield = getInvestmentExternalYield(currentInvestment, year)
      return getInvestmentAmount(year, externalYield)
    },
    [getCurrentInvestment, getInvestmentAmount, getInvestmentExternalYield]
  )

  const getInvestmentInternalAmount = useCallback(
    year => {
      const currentInvestment = getCurrentInvestment()[0]
      const externalYield = getInvestmentInternalYield(currentInvestment, year)
      return getInvestmentAmount(year, externalYield)
    },
    [getCurrentInvestment, getInvestmentAmount, getInvestmentInternalYield]
  )

  const getInvestmentDifference = useCallback(() => {
    const year = getMaxCompoundYear()
    return Math.round(
      Math.abs(
        getInvestmentInternalAmount(year) - getInvestmentExternalAmount(year)
      )
    )
  }, [
    getInvestmentExternalAmount,
    getInvestmentInternalAmount,
    getMaxCompoundYear,
  ])

  const getAllCompoundDataPerYear = useCallback(() => {
    const currentInvestment = getCurrentInvestment()[0]
    const validCompounds =
      currentInvestment &&
      Object.entries(currentInvestment.compound || {}).filter(
        ([_, compound]) => !!compound
      )

    let internalYield = 0
    let externalYield = 0

    const allCompoundDataPerYear = []
    validCompounds &&
      validCompounds.forEach(compound => {
        internalYield = (
          (getInvestmentInternalYield(currentInvestment, compound[0]) - 1) *
          100
        ).toFixed(2)
        externalYield = (
          (getInvestmentExternalYield(currentInvestment, compound[0]) - 1) *
          100
        ).toFixed(2)

        allCompoundDataPerYear.push({
          year: compound[0],
          internalYield,
          externalYield,
          internalAmount: getInvestmentInternalAmount(compound[0]).toFixed(),
          externalAmount: getInvestmentExternalAmount(compound[0]).toFixed(),
        })
      })

    return allCompoundDataPerYear
  }, [
    getCurrentInvestment,
    getInvestmentExternalAmount,
    getInvestmentInternalAmount,
    getInvestmentInternalYield,
    getInvestmentExternalYield,
  ])

  const handleInvestmentAmountChange = useCallback(
    newAmount => {
      setInvestmentAmount(newAmount)
      updateComparisonsData({
        bank: bank,
        id: comparisonId,
        investmentAmount: newAmount,
        investmentType: investmentType,
      })
    },
    [bank, comparisonId, updateComparisonsData, investmentType]
  )

  const handleInvestmentTypeChange = useCallback(
    newType => {
      setInvestmentType(newType)
      updateComparisonsData({
        bank: bank,
        id: comparisonId,
        investmentAmount: investmentAmount,
        investmentType: newType,
      })
    },
    [bank, comparisonId, investmentAmount, updateComparisonsData]
  )

  const handleBankChange = useCallback(
    newBank => {
      setBank(newBank)
      updateComparisonsData({
        bank: newBank,
        id: comparisonId,
        investmentAmount: investmentAmount,
        investmentType: investmentType,
      })
    },
    [comparisonId, investmentAmount, updateComparisonsData, investmentType]
  )

  const getAllYieldsForCurrentInvestment = useCallback(
    getYield => {
      const allYields = [
        getYield(getCurrentInvestment()[0], 1),
        getYield(getCurrentInvestment()[0], 3),
        getYield(getCurrentInvestment()[0], 5),
      ]

      const validYields = allYields.filter(currentYield => currentYield !== 1)

      return validYields
    },
    [getCurrentInvestment]
  )

  return (
    <>
      <InvestmentConfigurator
        contentText1={contentText1}
        contentText2={contentText2}
        contentText3={contentText3}
        investmentAmount={investmentAmount}
        setInvestmentAmount={handleInvestmentAmountChange}
        bank={bank}
        setBank={handleBankChange}
        investmentType={investmentType}
        setInvestmentType={handleInvestmentTypeChange}
        types={investments}
        arrow={arrow}
        locale={locale}
      />
      <div className={styles.graphResultSection}>
        <div className={styles.graphTitle}>{graphTitle}</div>
        <div className={styles.graphResultContainer}>
          <div className={styles.graphContainer}>
            <div className={styles.captionSection}>
              <div className={styles.captionContainer}>
                <ReactSVG
                  src={dot}
                  className={styles.dotContainer}
                  beforeInjection={svg => {
                    svg.classList.add(styles.dot)
                    svg.classList.add(styles.blueDot)
                  }}
                />
                <div className={styles.caption}>{mieuxPlanSelection}</div>
              </div>
              <div className={styles.captionContainer}>
                <ReactSVG
                  src={dot}
                  className={styles.dotContainer}
                  beforeInjection={svg => {
                    svg.classList.add(styles.dot)
                    svg.classList.add(styles.blackDot)
                  }}
                />
                <div className={styles.caption}>
                  {`${otherSelectionPrefix} ${bank}`}
                </div>
              </div>
            </div>
            <Graph
              allBaseYields={getAllYieldsForCurrentInvestment(
                getInvestmentExternalYield
              )}
              allSuperiorYields={getAllYieldsForCurrentInvestment(
                getInvestmentInternalYield
              )}
              startLabel={`${startLabel} ${getMaxCompoundYear()} ${
                getMaxCompoundYear() > 1 ? yearSuffixPlural : yearSuffix
              }`}
              endLabel={endLabel}
              getInvestment={getInvestmentAmount}
              getInvestmentInThePast={getInvestmentAmountInThePast}
              compoundData={getAllCompoundDataPerYear()}
            />
          </div>
          <div className={styles.resultComparison}>
            <span className={styles.highlighted}>{`${resultInDate}`}</span>
            <span>
              {` `}
              <FormattedDate
                value={computeDate(getCurrentInvestmentPerformanceDate())}
                year="2-digit"
                month="2-digit"
                day="2-digit"
              />
            </span>
            <div className={styles.comparisonTitle}>
              <div>{yourInvestments}</div>
              <div>{ourSelection}</div>
            </div>
            <div className={styles.results}>
              {getAllCompoundDataPerYear().map((compoundData, index) => (
                <div
                  className={classNames(styles.result, {
                    [styles.underlined]: index !== 2,
                  })}
                  key={compoundData.externalAmount}
                >
                  <div className={styles.resultYear}>{`${compoundData.year} ${
                    index === 0 ? yearSuffix : yearSuffixPlural
                  }`}</div>
                  <div className={styles.resultExternal}>
                    <NumberFormat
                      value={compoundData.externalAmount}
                      displayType={'text'}
                      thousandSeparator={' '}
                      suffix={'$ '}
                      prefix={' '}
                    />
                    <div>{`${compoundData.externalYield}%`}</div>
                  </div>
                  <div className={styles.resultArrows}>
                    <div className={styles.arrowContainer}>
                      <div className={styles.arrow}></div>
                      <div className={styles.arrow}></div>
                    </div>
                  </div>
                  <div className={styles.resultInternal}>
                    <NumberFormat
                      value={compoundData.internalAmount}
                      displayType={'text'}
                      thousandSeparator={' '}
                      suffix={'$ '}
                      prefix={' '}
                    />
                    <div>{`${compoundData.internalYield}%`}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.comparativeRankingTextContainer}>
          <div className={styles.comparativeText}>
            <span>{comparativeTextPart1}</span>
            <span className={styles.highlighted}>
              <NumberFormat
                value={investmentAmount}
                displayType={'text'}
                thousandSeparator={' '}
                suffix={'$ '}
                prefix={' '}
              />
            </span>
            <span>{comparativeTextPart2}</span>
            <span className={styles.highlighted}>
              {` `}
              <FormattedDate
                value={computeDate(
                  getCurrentInvestmentPerformanceDate(),
                  getMaxCompoundYear()
                )}
                year="numeric"
                month="long"
                day="numeric"
              />
              {` `}
            </span>
            <span>{comparativeTextPart3}</span>
            <NumberFormat
              value={getInvestmentDifference()}
              displayType={'text'}
              thousandSeparator={' '}
              suffix={'$ '}
              prefix={' '}
            />

            <span>{comparativeTextPart4}</span>
          </div>
          {getInvestmentRanking().indexOf(`0${rankingNumberSuffix}/0`) ===
            -1 && <div />}
        </div>
        <div
          className={classNames(styles.deleteButtonContainer, {
            [styles.hidden]: shouldHideDeleteButton,
          })}
        >
          <ReactSVG
            src={removeIcon}
            className={styles.iconContainer}
            beforeInjection={svg => {
              svg.classList.add(styles.icon)
            }}
            onClick={() => {
              deleteAction(comparisonId)
            }}
          />
        </div>
      </div>
    </>
  )
}
