import React, { useEffect, useCallback, useRef, memo, useState } from 'react'
import GraphPlot from 'src/components/MainPage/AnimatedGraph/Graph/GraphPlot'
import GraphSlider from 'src/components/MainPage/AnimatedGraph/Graph/GraphSlider'

import styles from './styles.module.css'

export default memo(
  ({
    investmentValue,
    annuityValue,
    yieldValue,
    axisLabel,
    axisStartLabel,
  }) => {
    const [currentYear, setCurrentYear] = useState(0)

    const graphContainerRef = useRef()
    const labelContainerRef = useRef()
    const superiorLabelRef = useRef()
    const baseLabelRef = useRef()
    const whiteLineRef = useRef()
    const blueLineRef = useRef()
    const startLabelRef = useRef()
    const labelRef = useRef()
    const endLabelRef = useRef()

    useEffect(() => {
      updateGraphHeight()
      window.addEventListener('resize', updateGraphHeight)
      return () => {
        window.removeEventListener('resize', updateGraphHeight)
      }
    })

    const updateGraphHeight = () => {
      if (graphContainerRef.current) {
        graphContainerRef.current.style.setProperty(
          '--width',
          getComputedStyle(graphContainerRef.current).getPropertyValue('width')
        )
      }
    }

    const getInvestment = useCallback(
      (years, isBase = false) => {
        const baseYield = 4.0
        const totalYield = isBase ? baseYield : baseYield + yieldValue
        const rate = (1 + totalYield / 100) ** (1 / 12) - 1
        const investment =
          years === 0
            ? investmentValue
            : annuityValue *
                (1 + rate) *
                (((1 + rate) ** (years * 12) - 1) / rate) +
              investmentValue * (1 + totalYield / 100) ** years
        return investment
      },
      [annuityValue, investmentValue, yieldValue]
    )

    const onMouseMove = event => {
      var rect = event.target.getBoundingClientRect()
      const xOffset = (22 / 201) * rect.width
      var x = event.clientX - rect.left - xOffset

      if (event.touches) {
        x = event.touches[0].clientX - rect.left - xOffset
      }
      const maxX = (628 / 804) * rect.width
      if (0 <= x && x <= maxX + maxX / 20) {
        const year = Math.floor((20 * x) / maxX)

        if (year !== currentYear) {
          update(year, false)
        }
      }
      event.stopPropagation()
    }

    const hideSlider = () => {
      if (
        !labelContainerRef.current ||
        !superiorLabelRef.current ||
        !baseLabelRef.current ||
        !whiteLineRef.current ||
        !blueLineRef.current ||
        !labelRef.current
      ) {
        return
      }

      labelContainerRef.current.style.setProperty('transition', 'unset')
      labelContainerRef.current.style.setProperty('opacity', '0')
      superiorLabelRef.current.style.setProperty('transition', 'unset')
      superiorLabelRef.current.style.setProperty('opacity', '0')
      baseLabelRef.current.style.setProperty('transition', 'unset')
      baseLabelRef.current.style.setProperty('opacity', '0')
      whiteLineRef.current.style.setProperty('transition', 'unset')
      whiteLineRef.current.style.setProperty('opacity', '0')
      blueLineRef.current.style.setProperty('transition', 'unset')
      blueLineRef.current.style.setProperty('opacity', '0')
      labelRef.current.style.setProperty('transition', 'unset')
      labelRef.current.style.setProperty('opacity', '0')
    }

    const placeSlider = (x, y, baseInvestmentY, yOffset) => {
      if (
        !labelContainerRef.current ||
        !superiorLabelRef.current ||
        !baseLabelRef.current ||
        !whiteLineRef.current ||
        !blueLineRef.current ||
        !labelRef.current
      ) {
        return
      }

      labelContainerRef.current.style.setProperty('--x', `${x.toFixed()}px`)
      labelContainerRef.current.style.setProperty(
        '--y',
        `${(y + 14).toFixed()}px`
      )
      superiorLabelRef.current.style.setProperty('--x', `${x.toFixed()}px`)
      superiorLabelRef.current.style.setProperty('--y', `${y.toFixed()}px`)
      baseLabelRef.current.style.setProperty('--x', `${(x - 1).toFixed()}px`)
      baseLabelRef.current.style.setProperty(
        '--y',
        `${(baseInvestmentY + yOffset).toFixed()}px`
      )
      whiteLineRef.current.style.setProperty(
        '--lineX',
        `${(x + 4).toFixed()}px`
      )
      whiteLineRef.current.style.setProperty(
        '--mobileLineX',
        `${(x + 1).toFixed()}px`
      )
      whiteLineRef.current.style.setProperty('--lineY', `${y.toFixed()}px`)
      whiteLineRef.current.style.setProperty('--width', `${y - yOffset + 4}px`)
      blueLineRef.current.style.setProperty('--lineX', `${(x + 3).toFixed()}px`)
      blueLineRef.current.style.setProperty('--mobileLineX', `${x.toFixed()}px`)
      blueLineRef.current.style.setProperty(
        '--lineY',
        `${(yOffset - 2).toFixed()}px`
      )
      labelRef.current.style.setProperty('--x', `${x.toFixed()}px`)
      labelRef.current.style.setProperty('--y', `${(yOffset - 40).toFixed()}px`)
    }

    const fadeInSlider = () => {
      const transition = 'opacity 500ms linear'

      if (
        !labelContainerRef.current ||
        !superiorLabelRef.current ||
        !baseLabelRef.current ||
        !whiteLineRef.current ||
        !blueLineRef.current ||
        !labelRef.current
      ) {
        return
      }

      labelContainerRef.current.style.setProperty('transition', transition)
      labelContainerRef.current.style.setProperty('opacity', '1')
      superiorLabelRef.current.style.setProperty('transition', transition)
      superiorLabelRef.current.style.setProperty('opacity', '1')
      baseLabelRef.current.style.setProperty('transition', transition)
      baseLabelRef.current.style.setProperty('opacity', '1')
      whiteLineRef.current.style.setProperty('transition', transition)
      whiteLineRef.current.style.setProperty('opacity', '1')
      blueLineRef.current.style.setProperty('transition', transition)
      blueLineRef.current.style.setProperty('opacity', '1')
      labelRef.current.style.setProperty('transition', transition)
      labelRef.current.style.setProperty('opacity', '1')
    }

    const placeAxisLabels = (xOffset, yOffset, x, maxX) => {
      if (!startLabelRef.current || !endLabelRef.current || !labelRef.current) {
        return
      }

      startLabelRef.current.style.setProperty('--x', `${xOffset.toFixed()}px`)
      startLabelRef.current.style.setProperty(
        '--y',
        `${(yOffset - 40).toFixed()}px`
      )
      endLabelRef.current.style.setProperty('--x', `${xOffset.toFixed()}px`)
      endLabelRef.current.style.setProperty(
        '--y',
        `${(yOffset - 40).toFixed()}px`
      )
      const startLabelWidth =
        getComputedStyle(startLabelRef.current).getPropertyValue('width') !==
        'auto'
          ? parseInt(
              getComputedStyle(startLabelRef.current).getPropertyValue('width'),
              10
            )
          : 0
      const labelWidth =
        getComputedStyle(labelRef.current).getPropertyValue('width') !== 'auto'
          ? parseInt(
              getComputedStyle(labelRef.current).getPropertyValue('width'),
              10
            )
          : 0
      const endLabelWidth = parseInt(
        getComputedStyle(endLabelRef.current).getPropertyValue('width'),
        10
      )
      if (x < xOffset + startLabelWidth + labelWidth / 2) {
        startLabelRef.current.style.setProperty('opacity', '0')
      } else {
        startLabelRef.current.style.setProperty('opacity', '1')
      }

      if (x >= maxX - endLabelWidth - labelWidth / 2 + 0.9 * xOffset) {
        endLabelRef.current.style.setProperty('opacity', '0')
      } else {
        endLabelRef.current.style.setProperty('opacity', '1')
      }
    }

    const update = useCallback(
      (year = 15, shoudlFadeIn = true) => {
        const width = parseInt(
          getComputedStyle(graphContainerRef.current).getPropertyValue('width'),
          10
        )
        const height = parseInt(
          getComputedStyle(graphContainerRef.current).getPropertyValue(
            'height'
          ),
          10
        )
        const xOffset = (22 / 201) * width
        const yOffset = (1 / 6) * height
        const maxX = (628 / 804) * width
        const maxY = (357 / 535) * height
        const investment = getInvestment(year)
        const baseInvestment = getInvestment(year, true)
        let x = (year / 20) * maxX
        let y = (maxY * investment) / getInvestment(20)
        let baseInvestmentY = (maxY * baseInvestment) / getInvestment(20)

        x += xOffset - 11
        y += yOffset

        if (shoudlFadeIn) {
          hideSlider()
        }

        placeSlider(x, y, baseInvestmentY, yOffset)

        if (shoudlFadeIn) {
          setTimeout(() => {
            fadeInSlider(
              labelContainerRef,
              superiorLabelRef,
              baseLabelRef,
              whiteLineRef,
              blueLineRef,
              labelRef
            )
          }, 2000)
        }

        placeAxisLabels(xOffset, yOffset, x, maxX)
        setCurrentYear(year)
      },
      [getInvestment]
    )

    useEffect(() => {
      update()
    }, [update])

    return (
      <div ref={graphContainerRef} className={styles.graphContainer}>
        <div
          role="presentation"
          className={styles.sliderAndTagContainer}
          onMouseMove={onMouseMove}
          onTouchMove={onMouseMove}
        >
          <div className={styles.tagsContainer}>
            <GraphSlider
              investment={getInvestment(currentYear).toFixed()}
              baseInvestment={getInvestment(currentYear, true).toFixed()}
              ref={{
                labelContainerRef: labelContainerRef,
                superiorLabelRef: superiorLabelRef,
                baseLabelRef: baseLabelRef,
                whiteLineRef: whiteLineRef,
                blueLineRef: blueLineRef,
              }}
            />
            <div ref={startLabelRef} className={styles.startLabel}>
              {axisStartLabel}
            </div>
            <div ref={labelRef} className={styles.label}>
              {`${axisLabel} ${currentYear}`}
            </div>
            <div ref={endLabelRef} className={styles.endLabel}>
              {axisLabel + ' 20'}
            </div>
          </div>
        </div>
        <GraphPlot
          investmentValue={investmentValue}
          annuityValue={annuityValue}
          yieldValue={yieldValue}
          axisLabel={axisLabel}
          upperDataSet={[
            { x: 0, y: investmentValue, y0: investmentValue },
            { x: 0.5, y: getInvestment(0.5), y0: getInvestment(0.5, true) },
            { x: 1, y: getInvestment(1), y0: getInvestment(1, true) },
            { x: 2, y: getInvestment(2), y0: getInvestment(2, true) },
            { x: 3, y: getInvestment(3), y0: getInvestment(3, true) },
            { x: 4, y: getInvestment(4), y0: getInvestment(4, true) },
            { x: 5, y: getInvestment(5), y0: getInvestment(5, true) },
            { x: 6, y: getInvestment(6), y0: getInvestment(6, true) },
            { x: 7, y: getInvestment(7), y0: getInvestment(7, true) },
            { x: 8, y: getInvestment(8), y0: getInvestment(8, true) },
            { x: 9, y: getInvestment(9), y0: getInvestment(9, true) },
            { x: 10, y: getInvestment(10), y0: getInvestment(10, true) },
            { x: 11, y: getInvestment(11), y0: getInvestment(11, true) },
            { x: 12, y: getInvestment(12), y0: getInvestment(12, true) },
            { x: 13, y: getInvestment(13), y0: getInvestment(13, true) },
            { x: 14, y: getInvestment(14), y0: getInvestment(14, true) },
            { x: 15, y: getInvestment(15), y0: getInvestment(15, true) },
            { x: 16, y: getInvestment(16), y0: getInvestment(16, true) },
            { x: 17, y: getInvestment(17), y0: getInvestment(17, true) },
            { x: 18, y: getInvestment(18), y0: getInvestment(18, true) },
            { x: 19, y: getInvestment(19), y0: getInvestment(19, true) },
            { x: 20, y: getInvestment(20), y0: getInvestment(20, true) },
          ]}
          lowerDataSet={[
            { x: 0, y: investmentValue, y0: 0 },
            { x: 0.5, y: getInvestment(0.5, true), y0: 0 },
            { x: 1, y: getInvestment(1, true), y0: 0 },
            { x: 2, y: getInvestment(2, true), y0: 0 },
            { x: 3, y: getInvestment(3, true), y0: 0 },
            { x: 4, y: getInvestment(4, true), y0: 0 },
            { x: 5, y: getInvestment(5, true), y0: 0 },
            { x: 6, y: getInvestment(6, true), y0: 0 },
            { x: 7, y: getInvestment(7, true), y0: 0 },
            { x: 8, y: getInvestment(8, true), y0: 0 },
            { x: 9, y: getInvestment(9, true), y0: 0 },
            { x: 10, y: getInvestment(10, true), y0: 0 },
            { x: 11, y: getInvestment(11, true), y0: 0 },
            { x: 12, y: getInvestment(12, true), y0: 0 },
            { x: 13, y: getInvestment(13, true), y0: 0 },
            { x: 14, y: getInvestment(14, true), y0: 0 },
            { x: 15, y: getInvestment(15, true), y0: 0 },
            { x: 16, y: getInvestment(16, true), y0: 0 },
            { x: 17, y: getInvestment(17, true), y0: 0 },
            { x: 18, y: getInvestment(18, true), y0: 0 },
            { x: 19, y: getInvestment(19, true), y0: 0 },
            { x: 20, y: getInvestment(20, true), y0: 0 },
          ]}
        />
      </div>
    )
  },
  (prevProps, nextProps) => !nextProps.shouldUpdate
)
