import { graphql } from 'gatsby'
import React from 'react'
import Layout from 'src/components/Layout'
import AnimatedGraph from 'src/components/MainPage/AnimatedGraph'
import Catchphrase from 'src/components/MainPage/Catchphrase'
import Clients from 'src/components/MainPage/Clients'
import Header from 'src/components/MainPage/Header'
import CompareInvestments from '../components/MainPage/CompareInvestments'
import Informations from '../components/MainPage/Informations'

const parseInvestment = ({ compound, performanceAsOfDate, ...i }) => {
  return Object.assign(i, {
    compound: JSON.parse(compound),
    performanceAsOfDate: new Date(performanceAsOfDate),
  })
}

export default ({
  pageContext,
  data: {
    mainPageData,
    currentInvestments: { internalInvestments, investments },
  },
}) => {
  return (
    <Layout pageContext={pageContext}>
      <Header
        headerData={mainPageData.header.value}
        headerButtonLink={mainPageData.headerButtonLink}
      />
      <Catchphrase catchphraseData={mainPageData.catchphrase.value} />
      <Clients
        clientsData={mainPageData.clientsAndSellingPoints.value}
        findOutMoreLink={mainPageData.findOutMoreLink}
      />
      <AnimatedGraph graphData={mainPageData.graph.value} />
      <Informations informationsData={mainPageData.informations.value} />
      <CompareInvestments
        compareInvestmentsData={{
          ...mainPageData.compareInvestments.value,
          internalInvestments: internalInvestments.map(i => parseInvestment(i)),
          investments: investments.map(i => parseInvestment(i)),
        }}
      />
    </Layout>
  )
}

export const query = graphql`
  query($locale: String) {
    currentInvestments(lang: { eq: $locale }) {
      internalInvestments {
        code
        compound
        performanceAsOfDate
      }
      investments {
        code
        company
        compound
        performanceAsOfDate
        type
      }
    }
    mainPageData: cockpitMainPage(lang: { eq: $locale }) {
      ...HeaderMain
      ...CatchphraseMain
      ...ClientsAndSellingPointsMain
      ...GraphMain
      ...InformationsMain
      ...CompareInvestments
    }
  }
`
