import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import GradientButton from 'src/components/GradientButton'

import styles from './styles.module.css'

const MainPageHeader = ({ headerData, headerButtonLink }) => {
  const [headerHeight, setHeaderHeight] = useState(1000)
  const mainPageHeaderAnimationContainerId = "mainPageHeaderAnimationContainerId"

  const handleHeaderHeight = () => {
    const animationContainer = document.getElementById(mainPageHeaderAnimationContainerId)
    if (animationContainer) {
      setHeaderHeight(animationContainer.clientHeight)
    } 
  }

  useEffect(() => {
    const resizeListener = handleHeaderHeight
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  })

  return (
    <div className={styles.header} style={{minHeight: `${headerHeight}px`}}>
      <div className={styles.headerTextContainer}>
        <div className={styles.headerTitle}>{headerData.title.value}</div>
        <div className={styles.headerDescription}>
          {headerData.description.value}
        </div>
        <div>
          <GradientButton
            link={headerButtonLink.value.to.value}
            text={headerData.buttonText.value}
            buttonStyle={styles.buttonSize}
          />
        </div>
      </div>
      <div className={styles.headerAnimationContainer} id={mainPageHeaderAnimationContainerId}>
        <video
          disableremoteplayback="true"
          autoPlay
          loop
          muted
          playsInline
          className={styles.animation}
          onPlay={handleHeaderHeight}
        >
          <source src={headerData.animation.value.publicURL} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}

export default MainPageHeader

export const query = graphql`
  fragment HeaderMain on CockpitMainPage {
    header {
      value {
        animation {
          value {
            publicURL
          }
        }
        buttonText {
          value
        }
        description {
          value
        }
        title {
          value
        }
      }
    }
    headerButtonLink {
      value {
        to {
          value
        }
      }
    }
  }
`
