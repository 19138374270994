import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'

import styles from './styles.module.css'

export default ({
  title,
  value,
  setValue,
  min,
  max,
  suffix,
  onMouseDown,
  onMouseUp,
  setOutsideValue,
  sliderId,
}) => {
  const [showInput, setShowInput] = useState(false)

  const adjustSliderCompletion = useCallback(
    newValue => {
      if (document.getElementById(sliderId)) {
        document.getElementById(sliderId).style.background =
          'linear-gradient(to right, var(--slider-active-color) 0%, var(--slider-active-color) ' +
          ((newValue - min) / (max - min)) * 100 +
          '%, var(--slider-inactive-color) ' +
          ((newValue - min) / (max - min)) * 100 +
          '%, var(--slider-inactive-color) 100%)'
        document.getElementById(sliderId + 'Number').value = newValue
        setValue(newValue)
      }
    },
    [sliderId, min, max, setValue]
  )

  useEffect(() => {
    adjustSliderCompletion(value)
  }, [value, title, min, max, adjustSliderCompletion])

  const toggleInput = () => {
    setShowInput(!showInput)
  }

  const changeValue = newValue => {
    newValue < min && (newValue = min)
    newValue > max && (newValue = max)
    adjustSliderCompletion(newValue)
    setShowInput(false)
    setOutsideValue && setOutsideValue(newValue)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      let newValue = parseInt(event.target.value)
      changeValue(newValue)
    }
  }

  const handleFocusLost = event => {
    let newValue = parseInt(event.target.value)
    changeValue(newValue)
  }

  return (
    <div className={styles.sliderContainer}>
      <div className={styles.sliderTitle}>{title}</div>
      <div className={styles.tagContainer}>
        <div className={styles.minTag}>
          <NumberFormat
            value={min}
            displayType={'text'}
            thousandSeparator={' '}
            suffix={suffix}
          />
        </div>
        <div
          className={classNames(styles.sliderTag, {
            [styles.hidden]: showInput,
          })}
          onClick={toggleInput}
          onKeyDown={toggleInput}
          role={'button'}
          tabIndex={0}
        >
          <NumberFormat
            value={value}
            displayType={'text'}
            thousandSeparator={' '}
            suffix={suffix}
          />
        </div>
        <input
          type="number"
          min={min}
          max={max}
          id={sliderId + 'Number'}
          defaultValue={value}
          onKeyDown={handleKeyDown}
          onBlur={handleFocusLost}
          className={classNames(styles.sliderTagInput, {
            [styles.hidden]: !showInput,
          })}
        />
        <div className={styles.maxTag}>
          <NumberFormat
            value={max}
            displayType={'text'}
            thousandSeparator={' '}
            suffix={suffix}
          />
        </div>
      </div>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        className={styles.slider}
        onChange={event => setValue(event.target.valueAsNumber)}
        id={sliderId}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onMouseUp}
        onTouchStart={onMouseDown}
      />
    </div>
  )
}
