import React from 'react'
import { VictoryAxis, VictoryChart, VictoryLine } from 'victory'
import { getGlobalCss } from 'src/utils/styleUtils'

export default ({ upperDataSet, lowerDataSet }) => {
  return (
    <VictoryChart
      animate={{
        duration: 2000,
        onLoad: { duration: 1000 },
      }}
    >
      <VictoryAxis
        style={{
          axis: { display: 'none' },
          tickLabels: { display: 'none' },
          grid: {
            stroke: ({ tick }) =>
              tick < 5 ? getGlobalCss('--tertiary') : 'none',
          },
        }}
      />
      <VictoryLine
        interpolation="basis"
        style={{
          data: {
            stroke: getGlobalCss('--tertiary'),
            opacity: 0.5,
          },
        }}
        data={upperDataSet}
      />
      <VictoryLine
        interpolation="basis"
        style={{
          data: {
            stroke: 'rgb(0, 0, 0)',
          },
        }}
        data={lowerDataSet}
      />
    </VictoryChart>
  )
}
