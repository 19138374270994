import React from 'react'
import GraphPlot from 'src/components/MainPage/CompareInvestments/Graph/GraphPlot'

import styles from './styles.module.css'

export default ({
  startLabel,
  endLabel,
  getInvestment,
  getInvestmentInThePast,
  compoundData,
}) => {
  const startingYearAmount = getInvestment(0)
  const lastCompoundData = compoundData[compoundData.length - 1]
  const lastYear = parseInt(lastCompoundData && lastCompoundData.year, 10)
  let externalBanks
  let mieuxPlanifier
  if (lastYear === 5) {
    const fifthYear = compoundData.find(data => data.year === "5")
    const thirdYear = compoundData.find(data => data.year === "3")
    const firstYear = compoundData.find(data => data.year === "1")

    const fifthYearInternalAmount = getInvestment(5, 1 + parseInt(fifthYear.internalYield, 10) / 100, startingYearAmount)
    const fifthYearExternalAmount = getInvestment(5, 1 + parseInt(fifthYear.externalYield, 10) / 100, startingYearAmount)

    const thirdYearInternalAmount = getInvestmentInThePast(3, 1 + parseInt(thirdYear.internalYield, 10) / 100, fifthYearInternalAmount)
    const thirdYearExternalAmount = getInvestmentInThePast(3, 1 + parseInt(thirdYear.externalYield, 10) / 100, fifthYearExternalAmount)

    const firstYearInternalAmount = getInvestmentInThePast(1, 1 + parseInt(firstYear.internalYield, 10) / 100, fifthYearInternalAmount)
    const firstYearExternalAmount = getInvestmentInThePast(1, 1 + parseInt(firstYear.externalYield, 10) / 100, fifthYearExternalAmount)

    externalBanks = [
      {x: 0, y: startingYearAmount},
      {x: 4, y: firstYearExternalAmount},
      {x: 2, y: thirdYearExternalAmount},
      {x: 5, y: fifthYearExternalAmount},
    ]
    mieuxPlanifier = [
      {x: 0, y: startingYearAmount},
      {x: 4, y: firstYearInternalAmount},
      {x: 2, y: thirdYearInternalAmount},
      {x: 5, y: fifthYearInternalAmount},
    ]
  } else if (lastYear === 3) {
    const thirdYear = compoundData.find(data => data.year === "3")
    const firstYear = compoundData.find(data => data.year === "1")

    const thirdYearInternalAmount = getInvestment(3, 1 + parseInt(thirdYear.internalYield, 10) / 100, startingYearAmount)
    const thirdYearExternalAmount = getInvestment(3, 1 + parseInt(thirdYear.externalYield, 10) / 100, startingYearAmount)

    const firstYearInternalAmount = getInvestmentInThePast(1, 1 + parseInt(firstYear.internalYield, 10) / 100, thirdYearInternalAmount)
    const firstYearExternalAmount = getInvestmentInThePast(1, 1 + parseInt(firstYear.externalYield, 10) / 100, thirdYearExternalAmount)

    externalBanks = [
      {x: 0, y: startingYearAmount},
      {x: 2, y: firstYearExternalAmount},
      {x: 3, y: thirdYearExternalAmount},
    ]
    mieuxPlanifier = [
      {x: 0, y: startingYearAmount},
      {x: 2, y: firstYearInternalAmount},
      {x: 3, y: thirdYearInternalAmount},
    ]
  } else if (lastYear === 1) {
    const firstYear = compoundData.find(data => data.year === "1")

    const firstYearInternalAmount = getInvestment(1, 1 + parseInt(firstYear.internalYield, 10) / 100, startingYearAmount)
    const firstYearExternalAmount = getInvestment(1, 1 + parseInt(firstYear.externalYield, 10) / 100, startingYearAmount)

    externalBanks = [
      {x: 0, y: startingYearAmount},
      {x: 1, y: firstYearExternalAmount},
    ]
    mieuxPlanifier = [
      {x: 0, y: startingYearAmount},
      {x: 1, y: firstYearInternalAmount},
    ]
  } else {
    externalBanks = [
      {x: 0, y: startingYearAmount},
    ]
    mieuxPlanifier = [
      {x: 0, y: startingYearAmount},
    ]
  }

  return (
    <div className={styles.graphContainer}>
      <div className={styles.overlay} />
      <div className={styles.underlay}>
        <GraphPlot
          upperDataSet={mieuxPlanifier}
          lowerDataSet={externalBanks}
          startLabel={startLabel}
          endLabel={endLabel}
        />
      </div>
      <div className={styles.graphLabelContainer}>
        <div className={styles.graphLabel}>{startLabel}</div>
        <div className={styles.graphLabel}>{endLabel}</div>
      </div>
    </div>
  )
}
