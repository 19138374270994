import React from 'react'
import NumberFormat from 'react-number-format'

import styles from './styles.module.css'

export default React.forwardRef((props, ref) => {
  const {
    labelContainerRef,
    superiorLabelRef,
    baseLabelRef,
    whiteLineRef,
    blueLineRef,
  } = ref
  return (
    <>
      <div ref={labelContainerRef} className={styles.investment}>
        <NumberFormat
          value={props.investment}
          displayType={'text'}
          thousandSeparator={' '}
          suffix={'$'}
        />
        <div className={styles.baseInvestment}>
          <NumberFormat
            value={props.baseInvestment}
            displayType={'text'}
            thousandSeparator={' '}
            suffix={'$'}
          />
        </div>
      </div>

      <div ref={superiorLabelRef} className={styles.superiorIndicator} />
      <div ref={baseLabelRef} className={styles.baseIndicator} />
      <div ref={whiteLineRef} className={styles.whiteLine} />
      <div ref={blueLineRef} className={styles.blueLine} />
    </>
  )
})
