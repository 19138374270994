exports.externalFunds = {
  363019: ['Prudent+', 192, 326],
  363029: ['Équilibré', 127, 326],
  365562: ['Équilibré+', 649, 822],
  365535: ['Croissance', 378, 822],
  365537: ['Croissance+', 346, 617],
  365553: ['Audacieux', 253, 617],

  367091: ['Prudent', 231, 266],
  367107: ['Prudent+', 200, 266],
  367090: ['Équilibré', 756, 822],
  367105: ['Croissance', 633, 822],
  367116: ['Prudent+', 90, 266],
  367113: ['Équilibré', 477, 822],
  367119: ['Équilibré+', 362, 822],
  367102: ['Croissance+', 271, 617],
  367137: ['Audacieux', 216, 617],

  429856: ['100% actions', 0, 0],
  363016: ['Prudent+', 0, 0],
  363036: ['Équilibré', 0, 0],
  363035: ['Croissance', 0, 0],
  363037: ['Audacieux', 0, 0],
  // 478622: ['100% actions', 0, 0], // Not working

  447281: ['Prudent', 0, 0],
  447324: ['Prudent+', 0, 0],
  447313: ['Équilibré', 0, 0],
  447317: ['Croissance', 0, 0],
  447309: ['Audacieux', 0, 0],
  447335: ['100% actions', 0, 0],

  92747: ['Audacieux', 450, 617],
  92743: ['100% actions', 740, 891],
  92746: ['Croissance', 584, 822],
  92745: ['Équilibré+', 715, 822],
  296594: ['Prudent+', 298, 328],
  92744: ['Équilibré', 243, 328],

  243853: ['Prudent', 179, 326],
  1536: ['Prudent', 67, 266],
  1534: ['Équilibré+', 263, 822],
  1535: ['Croissance+', 222, 617],
  6588: ['100% actions', 490, 891],

  315017: ['Prudent', 187, 328],
  470089: ['Prudent+', 0, 0],
  3484: ['Équilibré+', 312, 822],
  3485: ['Croissance+', 204, 617],
  3486: ['Audacieux', 123, 617],
  114694: ['Prudent', 293, 326],
  114692: ['Équilibré', 169, 268],
  114691: ['Croissance', 592, 822],
  114693: ['Croissance+', 457, 617],
  114690: ['Audacieux', 731, 891],

  447610: ['Prudent', 0, 0],
  151027: ['Prudent+', 0, 0],
  1430: ['Croissance', 0, 0],
  370363: ['Croissance+', 0, 0],
  447611: ['Audacieux', 0, 0],

  309315: ['100% revenus fixes', 100, 170],
  3680: ['Prudent+', 216, 328],
  3663: ['100% actions', 686, 891],
  403158: ['100% revenus fixes', 107, 172],
  3695: ['Croissance', 649, 822],
  3683: ['Croissance+', 364, 617],
  3690: ['Équilibré+', 232, 381],

  8279: ['Prudent+', 85, 328],
  8282: ['Croissance', 214, 822],
  8283: ['Croissance+', 142, 617],
  438252: ['100% actions', 0, 0],
  300739: ['100% actions', 401, 891],

  362277: ['100% revenus fixes', 338, 341],
  362349: ['Prudent+', 276, 328],
  362350: ['Équilibré+', 147, 268],
  362311: ['Croissance+', 567, 822],
  362298: ['Audacieux', 333, 617],
  362300: ['100% actions', 642, 891],
  442752: ['Prudent', 0, 0],
  442751: ['Audacieux', 0, 0],
  442753: ['100% actions', 0, 0],
  442757: ['Équilibré', 0, 0],
  442756: ['Croissance', 0, 0],

  473029: ['Prudent', 0, 0],
  473016: ['Prudent+', 0, 0],
  473002: ['Équilibré+', 0, 0],
  473011: ['Croissance', 0, 0],
  473024: ['Audacieux', 0, 0],
  2935: ['Prudent', 289, 328],
  2936: ['Prudent+', 207, 328],
  2937: ['Équilibré+', 526, 822],
  6102: ['Équilibré+', 343, 381],
  2938: ['Croissance', 302, 617],
  2939: ['Audacieux', 191, 617],
  2940: ['Audacieux', 508, 891],
}

exports.internalFunds = [
  {
    name: 'Prudent',
    1755: 0.05,
    8208: 0.05,
    12: 0.05,
    689664: 0.05,
    288562: 0.8,
  },
  {
    name: 'Prudent+',
    1755: 0.075,
    8208: 0.075,
    12: 0.075,
    689664: 0.075,
    288562: 0.7,
  },
  {
    name: 'Équilibré',
    1755: 0.1,
    8208: 0.1,
    12: 0.1,
    689664: 0.1,
    288562: 0.6,
  },
  {
    name: 'Équilibré+',
    1755: 0.125,
    8208: 0.125,
    12: 0.125,
    689664: 0.125,
    288562: 0.5,
  },
  {
    name: 'Croissance',
    1755: 0.15,
    8208: 0.15,
    12: 0.15,
    689664: 0.15,
    288562: 0.4,
  },
  {
    name: 'Croissance+',
    1755: 0.175,
    8208: 0.175,
    12: 0.175,
    689664: 0.175,
    288562: 0.3,
  },
  {
    name: 'Audacieux',
    1755: 0.2,
    8208: 0.2,
    12: 0.2,
    689664: 0.2,
    288562: 0.2,
  },
  {
    name: '100% actions',
    1755: 0.25,
    8208: 0.25,
    12: 0.25,
    689664: 0.25,
    288562: 0,
  },
  {
    name: '100% revenus fixes',
    1755: 0,
    8208: 0,
    12: 0,
    689664: 0,
    288562: 1,
  },
]
