import React from 'react'
import {
  VictoryArea,
  VictoryChart,
  VictoryAxis,
  VictoryContainer,
} from 'victory'

export default ({ upperDataSet, lowerDataSet }) => {
  return (
    <VictoryChart
      containerComponent={
        <VictoryContainer
          style={{
            zIndex: 1,
            position: 'absolute',
          }}
        />
      }
      animate={{
        duration: 2000,
        onLoad: { duration: 1000 },
      }}
    >
      <>
        {/* Fragment is used in order to prevent VictoryContainer to pass props down to the svg. */}
        <svg>
          <defs>
            <linearGradient id="linearGradient">
              <stop offset="0%" stopColor={'rgb(50, 84, 250)'} />
              <stop offset="50%" stopColor={'rgb(0, 150, 250)'} />
              <stop offset="100%" stopColor={'rgb(0, 236, 250)'} />
            </linearGradient>
          </defs>
        </svg>
      </>
      <VictoryAxis
        style={{
          axis: { display: 'none' },
          tickLabels: { display: 'none' },
        }}
      />
      <VictoryArea
        interpolation="basis"
        style={{
          data: {
            fill: 'url(#linearGradient)',
          },
        }}
        data={upperDataSet}
      />
      <VictoryArea
        interpolation="basis"
        style={{
          data: {
            fill: 'rgb(50, 84, 250)',
          },
        }}
        data={lowerDataSet}
      />
    </VictoryChart>
  )
}
