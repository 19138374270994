import { useEffect, useRef } from 'react'

export function useAnimatedOnMouseOver() {
  const ref = useRef()

  useEffect(() => {
    const refCurrent = ref.current
    const handleMouseOver = () => {
      refCurrent.play()
    }
    if (refCurrent) {
      refCurrent.addEventListener('mouseover', handleMouseOver)
    }
    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener('mouseover', () => handleMouseOver)
      }
    }
  }, [ref])
  
  return ref
}
