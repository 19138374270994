import { graphql } from 'gatsby'
import React, { Component } from 'react'
import IntersectionVisible from 'react-intersection-visible'

import styles from './styles.module.css'

class MainPageCatchphrase extends Component {
  render() {
    return (
      <div className={styles.catchphrase}>
        <div className={styles.textContainer}>
          <div className={styles.title}>
            {this.props.catchphraseData.title.value}
          </div>
          <div className={styles.description}>
            {this.props.catchphraseData.subtitle.value}
          </div>
        </div>
        <div className={styles.animationContainer}>
          <IntersectionVisible onShow={this.onShow}>
            <video
              disableremoteplayback="true"
              loop
              muted
              playsInline
              className={styles.animation}
            >
              <source
                src={this.props.catchphraseData.animation.value.publicURL}
                type="video/mp4"
              />
            </video>
          </IntersectionVisible>
        </div>
      </div>
    )
  }

  onShow() {
    document.getElementsByClassName(styles.animation)[0].play()
  }
}

export default MainPageCatchphrase

export const query = graphql`
  fragment CatchphraseMain on CockpitMainPage {
    catchphrase {
      value {
        title {
          value
        }
        subtitle {
          value
        }
        animation {
          value {
            publicURL
          }
        }
      }
    }
  }
`
