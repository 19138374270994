import { graphql } from 'gatsby'
import React, { useCallback, useRef } from 'react'
import IntersectionVisible from 'react-intersection-visible'
import { ReactSVG } from 'react-svg'

import { useAnimatedOnMouseOver } from 'src/utils/videoUtils'
import LocalizedLink from 'src/components/LocalizedLink'

import styles from './styles.module.css'

export default ({ informationsData }) => {
  const animationRef = useRef()
  const onShow = useCallback(() => {
    animationRef.current.play()
  }, [])

  const informationCardsVideoRef = informationsData.informationCard.value.map(
    () => useAnimatedOnMouseOver()
  )

  return (
    <div className={styles.informations}>
      <div className={styles.verticalLine} />
      <div className={styles.informationSectionContainer}>
        <div className={styles.informationCardContainer}>
          {informationsData.informationCard.value.map(
            (informationCard, index) => (
              <LocalizedLink 
                className={styles.informationCard}
                key={informationCard.value.title.value}
                to={informationCard.value.link.value.to.value}
              >
                <video
                  ref={informationCardsVideoRef[index]}
                  disableremoteplayback="true"
                  autoPlay
                  muted
                  playsInline
                  loop
                  className={styles.informationCardAnimation}
                >
                  <source
                    src={informationCard.value.animation.value.publicURL}
                    type="video/mp4"
                  />
                </video>

                <div className={styles.informationCardTextContainer}>
                  <div className={styles.informationCardTitle}>
                      {informationCard.value.title.value}
                  </div>
                  <div className={styles.arrow} />
                </div>

                <ReactSVG
                  src={informationsData.dot.value.publicURL}
                  className={styles.dotContainer}
                  beforeInjection={svg => {
                    svg.classList.add(styles.dot)
                  }}
                  />
              </LocalizedLink>
            )
          )}
        </div>
        <div className={styles.informationsInfo}>
          <h1 className={styles.title}>{informationsData.title.value}</h1>
          <div className={styles.description}>{informationsData.description.value}</div>
        </div>
        <div className={styles.informationAnimation}>
          <IntersectionVisible onShow={onShow}>
            <video
              ref={animationRef}
              disableremoteplayback="true"
              loop
              muted
              playsInline
              className={styles.animation}
            >
              <source
                src={informationsData.animation.value.publicURL}
                type="video/mp4"
              />
            </video>
          </IntersectionVisible>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment InformationsMain on CockpitMainPage {
    informations {
      value {
        informationCard {
          value {
            value {
              title {
                value
              }
              link {
                value {
                  to {
                    value
                  }
                }
              }
              image {
                value {
                  publicURL
                }
              }
              animation {
                value {
                  publicURL
                }
              }
            }
          }
        }
        title {
          value
        }
        description {
          value
        }
        animation {
          value {
            publicURL
          }
        }
        dot {
          value {
            publicURL
          }
        }
      }
    }
  }
`
