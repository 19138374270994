import { graphql } from 'gatsby'
import React from 'react'
import { ReactSVG } from 'react-svg'
import Graph from 'src/components/MainPage/AnimatedGraph/Graph'
import Slider from 'src/components/Slider'

import styles from './styles.module.css'

export default ({ graphData }) => {
  const [investmentValue, setInvestmentValue] = React.useState(100000)
  const [annuityValue, setAnnuityValue] = React.useState(300)
  const [yieldValue, setYieldValue] = React.useState(2)
  const [shouldUpdate, setShouldUpdate] = React.useState(true)

  const rightCLick = 2

  const handleMouseUp = event => {
    if (event.button !== rightCLick) {
      setShouldUpdate(true)
    }
  }

  const handleMouseDown = event => {
    if (event.button !== rightCLick) {
      setShouldUpdate(false)
    }
  }

  return (
    <div className={styles.graph}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          <div className={styles.highlighted}>
            {graphData.highlightedTitle.value}
          </div>
          {graphData.title.value}
        </div>
        <div className={styles.descriptionContainer}>
          {graphData.descriptionStart.value}
          <div className={styles.descriptionHighlighted}>
            {graphData.descriptionHighlighted.value}
          </div>
          {graphData.descriptionEnd.value}
        </div>
      </div>
      <div className={styles.labelContainer}>
        <div className={styles.slidersContainer}>
          <Slider
            title={graphData.investmentSlider.value}
            value={investmentValue}
            setValue={setInvestmentValue}
            min={5000}
            max={1000000}
            suffix={'$'}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            sliderId={'investmentValueSlider'}
          />
          <Slider
            title={graphData.annuitySlider.value}
            value={annuityValue}
            setValue={setAnnuityValue}
            min={0}
            max={2000}
            suffix={'$'}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            sliderId={'annuityValueSlider'}
          />
          <Slider
            title={graphData.yieldSlider.value}
            value={yieldValue}
            setValue={setYieldValue}
            min={1}
            max={3}
            suffix={'%'}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            sliderId={'yieldValueSlider'}
          />
        </div>
        <div className={styles.captionContainer}>
          <div className={styles.caption}>
            <ReactSVG
              src={graphData.gradientSquare.value.publicURL}
              className={styles.squareContainer}
              beforeInjection={svg => {
                svg.classList.add(styles.gradientSquare)
              }}
            />
            <div>{`${graphData.withOptimizedYield.value} (${4 +
              yieldValue}%)`}</div>
          </div>
          <div className={styles.caption}>
            <ReactSVG
              src={graphData.square.value.publicURL}
              className={styles.squareContainer}
              beforeInjection={svg => {
                svg.classList.add(styles.square)
              }}
            />
            <div>{graphData.withBaseYield.value}</div>
          </div>
        </div>
      </div>
      <div className={styles.graphContainer}>
        <Graph
          investmentValue={investmentValue}
          annuityValue={annuityValue}
          yieldValue={yieldValue}
          shouldUpdate={shouldUpdate}
          axisLabel={graphData.axisLabel.value}
          axisStartLabel={graphData.axisStartLabel.value}
        />
      </div>
    </div>
  )
}

export const query = graphql`
  fragment GraphMain on CockpitMainPage {
    graph {
      value {
        highlightedTitle {
          value
        }
        title {
          value
        }
        descriptionStart {
          value
        }
        descriptionHighlighted {
          value
        }
        descriptionEnd {
          value
        }
        withOptimizedYield {
          value
        }
        withBaseYield {
          value
        }
        investmentSlider {
          value
        }
        annuitySlider {
          value
        }
        yieldSlider {
          value
        }
        square {
          value {
            publicURL
          }
        }
        gradientSquare {
          value {
            publicURL
          }
        }
        axisLabel {
          value
        }
        axisStartLabel {
          value
        }
      }
    }
  }
`
