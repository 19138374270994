import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import InvestmentConfigurator from 'src/components/MainPage/CompareInvestments/InvestmentConfigurator'
import RadioButton from 'src/components/MainPage/CompareInvestments/RadioButton'
import Toast from 'src/components/Toast'

import styles from './styles.module.css'

export default ({
  title,
  description,
  contentText1,
  contentText2,
  contentText3,
  investmentAmount,
  setInvestmentAmount,
  bank,
  setBank,
  investmentType,
  setInvestmentType,
  contactOptions,
  loginOption,
  setLoginOption,
  buttonText,
  signIn,
  investments,
  captchaCompleted,
  userPhoneNumber,
  setUserPhoneNumber,
  confirmPhoneCode,
  codeSent,
  arrow,
  enterCodeButtonText,
  enterPhoneNumberText,
  locale,
  formatMessage,
  saveCurrentData,
}) => {
  const [waitingForCode, setWaitingForCode] = useState(false)
  const [shouldShowToast, setShouldShowToast] = useState(false)
  const [toastText, setToastText] = useState('')

  const handleSubmit = event => {
    event.preventDefault()
    if (loginOption === 'Cellulaire') {
      if (captchaCompleted) {
        if (!codeSent) {
          signIn()
          saveCurrentData()
        }
        if (codeSent) {
          confirmPhoneCode(document.getElementById('phoneCode').value)
          document.getElementById('phoneCode').value = ''
        }
      } else {
        setToastText(formatMessage({ id: 'pages.HomePage.fillRecaptcha' }))
        setShouldShowToast(true)
      }
    } else {
      signIn()
    }
  }

  useEffect(() => {
    setWaitingForCode(true)
  }, [codeSent])

  if (!captchaCompleted && waitingForCode) {
    setWaitingForCode(false)
  }

  const hideToast = () => {
    setShouldShowToast(false)
  }

  return (
    <>
      <Toast show={shouldShowToast} text={toastText} close={hideToast} />
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      <InvestmentConfigurator
        contentText1={contentText1}
        contentText2={contentText2}
        contentText3={contentText3}
        investmentAmount={investmentAmount}
        setInvestmentAmount={setInvestmentAmount}
        bank={bank}
        setBank={setBank}
        investmentType={investmentType}
        setInvestmentType={setInvestmentType}
        types={investments}
        arrow={arrow}
        locale={locale}
      />
      <div className={styles.loginMethodContainer}>
        {contactOptions.map(contactOption => {
          return (
            <RadioButton
              key={contactOption.value.loginOptions.value.cockpitId}
              value={contactOption.value.loginOptions.value.name.value}
              setValue={setLoginOption}
              isChecked={
                loginOption ===
                contactOption.value.loginOptions.value.name.value
              }
            />
          )
        })}
      </div>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.loginFormContainer}>
          {contactOptions.map(contactOption => {
            return (
              <div
                key={contactOption.value.loginOptions.value.cockpitId}
                className={classNames(styles.loginForm, {
                  [styles.hidden]:
                    loginOption !==
                    contactOption.value.loginOptions.value.name.value,
                })}
              >
                {contactOption.value.defaultText && (
                  <>
                    <div
                      className={classNames(styles.inputCaptchaContainer, {
                        [styles.hidden]: waitingForCode,
                      })}
                    >
                      <div className={styles.underlined}>
                        <PhoneInput
                          placeholder={enterPhoneNumberText}
                          value={userPhoneNumber}
                          onChange={setUserPhoneNumber}
                          country={'ca'}
                          onlyCountries={['ca']}
                          required={loginOption === 'Cellulaire'}
                        />
                      </div>
                      <div
                        id="recaptcha-container"
                        className={styles.recaptcha}
                      />
                    </div>
                    <div
                      className={classNames(
                        styles.underlined,
                        styles.codeInput,
                        {
                          [styles.hidden]: !waitingForCode,
                        }
                      )}
                    >
                      <input
                        id="phoneCode"
                        type="text"
                        required={waitingForCode}
                        placeholder={enterCodeButtonText}
                        className={styles.textInput}
                      />
                    </div>
                  </>
                )}
              </div>
            )
          })}
        </div>
        <div>
          <div className={styles.buttonContainer}>
            <button type="submit" className={styles.button}>
              {buttonText}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
