import React from 'react'

import styles from './styles.module.css'

export default ({ value, setValue, isChecked }) => {
  return (
    <label className={styles.radio}>
      <input
        className={styles.radioButton}
        type="radio"
        value={value}
        checked={isChecked}
        onChange={event => {
          setValue(event.target.value)
        }}
      />
      <span className={styles.radioLabel}>{value}</span>
    </label>
  )
}
