import { graphql } from 'gatsby'
import React from 'react'
import { ReactSVG } from 'react-svg'

import LocalizedLink from 'src/components/LocalizedLink'

import styles from './styles.module.css'

export default ({ clientsData, findOutMoreLink }) => {
  return (
    <div className={styles.clients}>
      <div className={styles.sellingPointContainer}>
        <div className={styles.sellingPoints}>
          {clientsData.sellingPoints.value.map(sellingPoint => (
            <div
              key={sellingPoint.value.firstLine.value}
              className={styles.sellingPoint}
            >
              <ReactSVG
                src={clientsData.dot.value.publicURL}
                className={styles.dotContainer}
                beforeInjection={svg => {
                  svg.classList.add(styles.dot)
                }}
              />
              <div className={styles.sellingPointTextContainer}>
                <div>{sellingPoint.value.firstLine.value}</div>
                {sellingPoint.value.highlightedSecondLine && (
                  <div className={styles.highlighted}>
                    {sellingPoint.value.highlightedSecondLine.value}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className={styles.findOutMoreContainer}>
            <div className={styles.titleAndArrowContainer}>
              <div className={styles.findOutMoreTitle}>
                <LocalizedLink to={findOutMoreLink.value.to.value}>
                  {clientsData.findOutMore.value.title.value}
                </LocalizedLink>
              </div>
              <div className={styles.arrow} />
            </div>
            <div className={styles.findOutMoreDescription}>
              {clientsData.findOutMore.value.description.value}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.clientList}>
        <div className={styles.star}>*</div>
        {clientsData.clients.value.map((client) => (
          <ReactSVG
            key={client.id}
            src={client.publicURL}
            className={styles.clientImageContainer}
            beforeInjection={svg => {
              svg.classList.add(styles.clientImage)
            }}
          ></ReactSVG>
        ))}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment ClientsAndSellingPointsMain on CockpitMainPage {
    findOutMoreLink {
      value {
        to {
          value
        }
      }
    }
    clientsAndSellingPoints {
      value {
        sellingPoints {
          value {
            value {
              firstLine {
                value
              }
              highlightedSecondLine {
                value
              }
            }
          }
        }
        findOutMore {
          value {
            title {
              value
            }
            description {
              value
            }
          }
        }
        clients {
          value {
            publicURL
            id
          }
        }
        dot {
          value {
            publicURL
          }
        }
      }
    }
  }
`
