import React, { useState, useEffect, useCallback, useMemo } from 'react'
import AmountInput from 'src/components/MainPage/CompareInvestments/AmountInput'
import DropdownInput from 'src/components/MainPage/CompareInvestments/DropdownInput'

import styles from './styles.module.css'

export default ({
  contentText1,
  contentText2,
  contentText3,
  investmentAmount,
  setInvestmentAmount,
  bank,
  setBank,
  investmentType,
  setInvestmentType,
  types,
  arrow,
  locale,
}) => {
  const [openedModal, setOpenedModal] = useState(undefined)


  const desjardins = new RegExp('[Dd]esjardins')
  const banks = useMemo(
    () => [...new Set(types.map(investment => investment.company))]
    .sort((x, y) => {
        return desjardins.test(x.company) ? -1 : desjardins.test(y.company) ? 1 : 0
    }),
    [types]
  )
  const investmentTypesPerBank = useMemo(
    () =>
      types.reduce(
        (accumulator, investment) => ({
          ...accumulator,
          [investment.company]: [
            ...(accumulator[investment.company] ?? []),
            investment.type,
          ],
        }),
        {}
      ),
    [types]
  )

  const resetBank = useCallback(() => {
    if (banks.length > 0) {
      setBank(banks[0])
    }
  }, [setBank, banks])

  const resetInvestmentType = useCallback(() => {
    if (investmentTypesPerBank[bank]?.length > 0) {
      setInvestmentType(investmentTypesPerBank[bank][0])
    }
  }, [setInvestmentType, investmentTypesPerBank, bank])

  useEffect(() => {
    if (bank === undefined) {
      resetBank()
    }
    if (
      investmentType === undefined ||
      !investmentTypesPerBank[bank]?.includes(investmentType)
    ) {
      resetInvestmentType()
    }
  }, [
    bank,
    investmentType,
    investmentTypesPerBank,
    resetBank,
    resetInvestmentType,
  ])

  return (
    <div className={styles.InvestmentConfiguratorContainer}>
      <span className={styles.grouped}>
        <span>{`${contentText1} `}</span>
        <AmountInput
          value={investmentAmount}
          setValue={setInvestmentAmount}
          modalId={0}
          openedModal={openedModal}
          setOpenedModal={setOpenedModal}
          arrow={arrow}
          locale={locale}
        />
      </span>
      <span className={styles.grouped}>
        <span>{contentText2}</span>
        <DropdownInput
          value={bank}
          setValue={setBank}
          modalId={1}
          openedModal={openedModal}
          setOpenedModal={setOpenedModal}
          values={[banks]}
          arrow={arrow}
        />
        <span>{contentText3}</span>
      </span>
      <span className={styles.grouped}>
        <DropdownInput
          value={investmentType}
          setValue={setInvestmentType}
          modalId={2}
          openedModal={openedModal}
          setOpenedModal={setOpenedModal}
          values={[investmentTypesPerBank[bank]]}
          arrow={arrow}
        />
      </span>
    </div>
  )
}
