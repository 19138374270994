import classNames from 'classnames'
import React, { useRef } from 'react'
import { ReactSVG } from 'react-svg'

import styles from './styles.module.css'

export default ({
  value,
  setValue,
  modalId,
  openedModal,
  setOpenedModal,
  values,
  arrow,
}) => {
  const modalRef = useRef()
  const tagRef = useRef()

  const handleOnBlur = event => {
    if (
      !modalRef.current.contains(event.relatedTarget) &&
      document.body.classList.contains('user-is-tabbing')
    ) {
      setOpenedModal(undefined)
    }
  }

  const toggleModal = () => {
    openedModal === modalId
      ? setOpenedModal(undefined)
      : setOpenedModal(modalId)
  }

  const handleKeyPress = () => {
    toggleModal()
  }

  const handleEventWithData = event => {
    setValue(event.target.getAttribute('data-listed-value'))
    toggleModal()
  }

  return (
    <span className={styles.dropdownInputContainer}>
      <div className={styles.labelContainer}>
        <span
          role={'listbox'}
          tabIndex={0}
          onClick={toggleModal}
          onKeyPress={handleKeyPress}
          ref={tagRef}
          className={styles.label}
        >
          {value}
        </span>
        <span
          role={'menuitem'}
          ref={modalRef}
          onBlur={handleOnBlur}
          className={classNames(styles.modalContainer, {
            [styles.hidden]: openedModal !== modalId,
          })}
        >
          {values[0] &&
            values[0].sort().map((listedValue, index) => {
              return (
                <div
                  role="option"
                  aria-selected={value === listedValue}
                  onClick={handleEventWithData}
                  className={classNames(styles.listedValue, {
                    [styles.underlined]: index !== values[0].length - 1,
                    [styles.marginTop]: index !== 0,
                  })}
                  data-listed-value={listedValue}
                  tabIndex={0}
                  onKeyPress={handleEventWithData}
                  key={listedValue}
                >
                  {listedValue}
                </div>
              )
            })}
        </span>
      </div>

      <ReactSVG
        onClick={toggleModal}
        src={arrow.publicURL}
        className={styles.arrow}
      />
    </span>
  )
}
