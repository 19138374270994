import classNames from 'classnames'
import { auth as Auth } from 'firebase/app'
import { graphql } from 'gatsby'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import IntersectionVisible from 'react-intersection-visible'
import { injectIntl } from 'react-intl'
import { ReactSVG } from 'react-svg'
import Comparison from 'src/components/MainPage/CompareInvestments/Comparison'
import Login from 'src/components/MainPage/CompareInvestments/Login'
import Toast from 'src/components/Toast'
import { withFirebase } from 'src/contexts/Firebase'
import { v4 as uuidv4 } from 'uuid'
import styles from './styles.module.css'

const CompareInvestments = ({
  compareInvestmentsData,
  compareInvestmentsData: { internalInvestments, investments },
  firebase,
  intl: { locale, formatMessage },
}) => {
  const desjardins = new RegExp('[Dd]esjardins')
  const [investmentAmount, setInvestmentAmount] = useState(50000)
  const [bank, setBank] = useState(undefined)
  const [investmentType, setInvestmentType] = useState(undefined)
  const [loginOption, setLoginOption] = useState(
    compareInvestmentsData.contactOptions.value[0].value.loginOptions.value.name
      .value
  )
  const [isLoading, setIsLoading] = useState(true)
  const [comparisonsData, setComparisonsData] = useState([])
  const [captchaCompleted, setCaptchaCompleted] = useState(false)
  const [userPhoneNumber, setUserPhoneNumber] = useState(undefined)
  const [codeSent, setCodeSent] = useState(false)
  const [shouldShowToast, setShouldShowToast] = useState(false)
  const [toastText, setToastText] = useState('')
  const [currentStage, setCurrentStage] = useState(0)
  const [isComingFromRedirect, setIsComingFromRedirect] = useState(false)
  const stages = ['authentication', 'comparison']
  const comparisonRef = useRef()

  const nextStage = useCallback(
    (step = 1) => {
      if (stages[currentStage] === 'comparison') {
        return
      } else {
        setCurrentStage(currentStage + step)
      }
    },
    [currentStage, stages]
  )

  const startLoading = useCallback(() => setIsLoading(true), [])

  const stopLoading = useCallback(() => {
    if (firebase && investments.length !== 0) setIsLoading(false)
  }, [firebase, investments])

  const addNewComparison = useCallback(() => {
    const newComparisons = [
      ...comparisonsData,
      {
        bank: bank,
        id: uuidv4(),
        investmentAmount: investmentAmount,
        investmentType: investmentType,
      },
    ]
    setComparisonsData(newComparisons)
  }, [
    investmentAmount,
    bank,
    investmentType,
    setComparisonsData,
    comparisonsData,
  ])

  const addNewComparisonFromStorage = useCallback(() => {
    if (window) {
      const data = JSON.parse(window.localStorage.getItem('comparisonsData'))
      setComparisonsData(data)
    }
  }, [
    investmentAmount,
    bank,
    investmentType,
    setComparisonsData,
    comparisonsData,
  ])

  const initializeComparisons = useCallback(() => {
    startLoading()
    const database = firebase.database()
    const userId = firebase.auth().currentUser.uid
    database
      .ref('users/' + userId)
      .once('value')
      .then(userData => {
        nextStage()
        userData.val()
          ? setComparisonsData([...userData.val()])
          : addNewComparisonFromStorage()
        stopLoading()
      })
      .catch(() => {
        setToastText(formatMessage({ id: 'error' }))
        setShouldShowToast(true)
      })
  }, [addNewComparison, firebase, formatMessage, nextStage])

  const deleteComparison = useCallback(
    comparisonId => {
      const comparison = document.getElementById(comparisonId)
      comparison.style.opacity = '0'
      comparison.style.transition = 'opacity 800ms ease-in-out'
      window.setTimeout(() => {
        comparison.style.display = 'none'
        setComparisonsData(
          [...comparisonsData].filter(comparison => {
            return comparison.id !== comparisonId
          })
        )
      }, 800)
    },
    [comparisonsData]
  )

  const onShow = useCallback(() => {
    document.getElementsByClassName(styles.animation)[0].play()
  }, [])

  const resetPhoneLogin = useCallback(() => {
    setCaptchaCompleted(false)
    setCodeSent(false)
    stopLoading()
  }, [stopLoading])

  const updateUrl = useCallback(() => {
    window.history.pushState(
      '',
      '',
      encodeURI(
        `?bank=${bank}&investmentAmount=${investmentAmount}&investmentType=${investmentType}#compareInvestments`
      )
    )
  }, [bank, investmentAmount, investmentType])

  const signIn = useCallback(() => {
    if (!firebase) return
    switch (loginOption) {
      case 'Google': {
        updateUrl()
        firebase.auth().signInWithRedirect(new Auth.GoogleAuthProvider())
        break
      }
      case 'Facebook': {
        updateUrl()
        firebase.auth().signInWithRedirect(new Auth.FacebookAuthProvider())
        break
      }
      case 'Cellulaire': {
        const appVerifier = window.recaptchaVerifier
        firebase
          .auth()
          .signInWithPhoneNumber(userPhoneNumber, appVerifier)
          .then(confirmationResult => {
            window.confirmationResult = confirmationResult
            setCodeSent(true)
          })
          .catch(() => {
            setToastText(
              formatMessage({ id: 'pages.HomePage.invalidPhoneNumber' })
            )
            setShouldShowToast(true)
            resetPhoneLogin()
          })
        break
      }
      default:
        return
    }
  }, [
    firebase,
    formatMessage,
    loginOption,
    resetPhoneLogin,
    updateUrl,
    userPhoneNumber,
  ])

  const confirmPhoneCode = useCallback(
    code => {
      startLoading()
      window.scrollTo(0, comparisonRef.current.offsetTop)
      window.confirmationResult
        .confirm(code)
        .then(() => nextStage())
        .catch(error => {
          if (error.code === 'auth/invalid-verification-code') {
            setToastText(
              formatMessage({ id: 'pages.HomePage.wrongPhoneCodeVerification' })
            )
            setShouldShowToast(true)
          } else {
            setToastText(formatMessage({ id: 'error' }))
          }
          resetPhoneLogin()
        })
    },
    [formatMessage, nextStage, resetPhoneLogin, startLoading]
  )

  const updateComparisonsData = useCallback(
    newData => {
      const newComaprisonData = [...comparisonsData]
      newComaprisonData.forEach(comparison => {
        if (comparison.id === newData.id) {
          comparison.investmentAmount = newData.investmentAmount
          comparison.investmentType = newData.investmentType
          comparison.bank = newData.bank
        }
      })
      setComparisonsData(newComaprisonData)
      if (window) {
        window.localStorage.setItem(
          'comparisonsData',
          JSON.stringify(newComaprisonData)
        )
      }
    },
    [comparisonsData]
  )

  const saveToDatabase = useCallback(() => {
    if (window) {
      window.localStorage.setItem(
        'comparisonsData',
        JSON.stringify(comparisonsData)
      )
    }
    if (!firebase) return
    if (!firebase.auth().currentUser) return
    if (!comparisonsData) return
    if (!comparisonsData[0]) return
    if (!comparisonsData[0].investmentType) return
    const database = firebase.database()
    const userId = firebase.auth().currentUser.uid
    database.ref('users/' + userId).set(comparisonsData)
  }, [firebase, comparisonsData])

  const hideToast = () => setShouldShowToast(false)

  const initializeDataAfterRedirect = useCallback(() => {
    if (window.location.hash === '#compareInvestments') {
      setIsComingFromRedirect(true)
      let urlParams = {}
      let match,
        pl = /\+/g,
        search = /([^&=]+)=?([^&]*)/g,
        decode = function(s) {
          return decodeURIComponent(s.replace(pl, ' '))
        },
        query = window.location.search.substring(1)
      while ((match = search.exec(query)))
        urlParams[decode(match[1])] = decode(match[2])
      setInvestmentAmount(urlParams.investmentAmount)
      setInvestmentType(urlParams.investmentType)
      setBank(urlParams.bank)
      window.location.hash = '#compareInvestments'
      window.history.replaceState('', '', window.location.pathname)
    }
  }, [])

  const initialiseFirebase = useCallback(() => {
    if (firebase) {
      firebase.auth().languageCode = locale
      firebase.auth().onAuthStateChanged(loggedUser => {
        if (loggedUser) initializeComparisons()
      })
      if (window.recaptchaVerifier === undefined) {
        window.recaptchaVerifier = new Auth.RecaptchaVerifier(
          'recaptcha-container',
          {
            size: 'normal',
            callback: () => setCaptchaCompleted(true),
            'expired-callback': () => {
              resetPhoneLogin()
              setToastText(
                formatMessage({ id: 'pages.HomePage.recaptchaExpired' })
              )
              setShouldShowToast(true)
            },
          }
        )
        window.recaptchaVerifier.render()
      }
    }
  }, [firebase])

  useEffect(() => initialiseFirebase(), [firebase, initialiseFirebase])

  useEffect(() => saveToDatabase(), [comparisonsData, saveToDatabase])

  useEffect(() => {
    if (window) {
      window.localStorage.setItem(
        'comparisonsData',
        JSON.stringify([
          {
            id: uuidv4(),
            bank,
            investmentType,
            investmentAmount,
          },
        ])
      )
    }
  })

  useEffect(() => initializeDataAfterRedirect(), [initializeDataAfterRedirect])

  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    )
    head.appendChild(script)
  })

  useEffect(() => {
    if (firebase) {
      if (firebase.auth().currentUser) {
        investments.length > 0 && comparisonsData.length > 0 && stopLoading()
        setIsComingFromRedirect(false)
      } else if (isComingFromRedirect) {
        return
      } else {
        investments.length > 0 && stopLoading()
      }
    }
  }, [firebase, investments, stopLoading, comparisonsData])

  return (
    <div
      id="compareInvestments"
      ref={comparisonRef}
      className={styles.compareInvestments}
    >
      <Toast show={shouldShowToast} text={toastText} close={hideToast} />
      <div
        className={classNames(styles.loading, { [styles.hidden]: !isLoading })}
      >
        <div className={styles.loadingRing} />
      </div>
      <div
        className={classNames(styles.loginContainer, {
          [styles.hidden]:
            stages[currentStage] !== 'authentication' || isLoading,
        })}
      >
        <Login
          title={compareInvestmentsData.title.value}
          description={compareInvestmentsData.description.value}
          contentText1={compareInvestmentsData.contentText1.value}
          contentText2={compareInvestmentsData.contentText2.value}
          contentText3={compareInvestmentsData.contentText3.value}
          investmentAmount={investmentAmount}
          setInvestmentAmount={setInvestmentAmount}
          bank={bank}
          setBank={setBank}
          investmentType={investmentType}
          setInvestmentType={setInvestmentType}
          contactOptions={compareInvestmentsData.contactOptions.value}
          loginOption={loginOption}
          setLoginOption={setLoginOption}
          buttonText={compareInvestmentsData.buttonText.value}
          signIn={signIn}
          investments={investments}
          captchaCompleted={captchaCompleted}
          userPhoneNumber={userPhoneNumber}
          setUserPhoneNumber={setUserPhoneNumber}
          confirmPhoneCode={confirmPhoneCode}
          codeSent={codeSent}
          arrow={compareInvestmentsData.arrow.value}
          enterCodeButtonText={compareInvestmentsData.enterCodeButtonText.value}
          enterPhoneNumberText={
            compareInvestmentsData.enterPhoneNumberText.value
          }
          locale={locale}
          formatMessage={formatMessage}
        />
      </div>
      <div
        className={classNames(styles.resultContainer, {
          [styles.hidden]: stages[currentStage] !== 'comparison' || isLoading,
        })}
      >
        <div className={styles.title}>
          {compareInvestmentsData.secondSideTitle.value}
        </div>
        {comparisonsData.map(comparison => {
          return (
            <div
              className={styles.comparisonContainer}
              key={comparison.id}
              id={comparison.id}
            >
              <Comparison
                contentText1={compareInvestmentsData.contentText1.value}
                contentText2={compareInvestmentsData.contentText2.value}
                contentText3={compareInvestmentsData.contentText3.value}
                graphTitle={compareInvestmentsData.graphTitle.value}
                dot={compareInvestmentsData.dot.value.publicURL}
                mieuxPlanSelection={
                  compareInvestmentsData.mieuxPlanSelection.value
                }
                otherSelectionPrefix={
                  compareInvestmentsData.otherSelectionPrefix.value
                }
                startLabel={compareInvestmentsData.graphStartLabel.value}
                endLabel={compareInvestmentsData.graphEndLabel.value}
                resultInDate={compareInvestmentsData.resultInDate.value}
                yourInvestments={compareInvestmentsData.yourInvestments.value}
                ourSelection={compareInvestmentsData.ourSelection.value}
                yearSuffix={compareInvestmentsData.yearSuffix.value}
                yearSuffixPlural={compareInvestmentsData.yearSuffixPlural.value}
                comparativeTextPart1={
                  compareInvestmentsData.comparativeTextPart1.value
                }
                comparativeTextPart2={
                  compareInvestmentsData.comparativeTextPart2.value
                }
                comparativeTextPart3={
                  compareInvestmentsData.comparativeTextPart3.value
                }
                comparativeTextPart4={
                  compareInvestmentsData.comparativeTextPart4.value
                }
                rankingTextPart1={compareInvestmentsData.rankingTextPart1.value}
                rankingTextPart2={compareInvestmentsData.rankingTextPart2.value}
                rankingTextPart3={compareInvestmentsData.rankingTextPart3.value}
                rankingNumberSuffix={
                  compareInvestmentsData.rankingNumberSuffix.value
                }
                arrow={compareInvestmentsData.arrow.value}
                defaultInvestmentAmount={comparison.investmentAmount}
                defaultBank={comparison.bank}
                defaultInvestmentType={comparison.investmentType}
                deleteAction={deleteComparison}
                comparisonId={comparison.id}
                shouldHideDeleteButton={comparisonsData.length === 1}
                investments={investments}
                internalInvestments={internalInvestments}
                updateComparisonsData={updateComparisonsData}
                removeIcon={compareInvestmentsData.removeIcon.value.publicURL}
                locale={locale}
                firstBank={(() => {
                  const bank = comparisonsData.find(comparison =>
                    desjardins.test(comparison.bank)
                  )
                  if (bank) {
                    return bank.bank
                  } else {
                    return 'Choisir...'
                  }
                })()}
                firstInvestmentType={(() => {
                  const bank = comparisonsData.find(comparison =>
                    desjardins.test(comparison.bank)
                  )
                  if (bank) {
                    return bank.investmentType
                  } else {
                    return 'Choisir...'
                  }
                })()}
              />
            </div>
          )
        })}

        <div
          className={classNames(styles.buttonContainer, {
            [styles.hidden]: stages[currentStage] !== 'comparison',
          })}
        >
          <ReactSVG
            src={compareInvestmentsData.addIcon.value.publicURL}
            className={styles.iconContainer}
            beforeInjection={svg => {
              svg.classList.add(styles.icon)
            }}
            onClick={() => addNewComparison()}
          />
        </div>
        <div
          className="calendly-inline-widget"
          data-url={compareInvestmentsData.appointment.value}
        />
        <div className={styles.animationContainer}>
          <video
            disableremoteplayback="true"
            autoPlay
            muted
            playsInline
            className={styles.animation}
          >
            <source
              src={compareInvestmentsData.animation.value.publicURL}
              type="video/mp4"
            />
          </video>
        </div>
        <IntersectionVisible onShow={onShow} />
        <div className={styles.disclaimerContainer}>
          <div>{compareInvestmentsData.disclaimerTextPart1.value}</div>
          <div>{compareInvestmentsData.disclaimerTextPart2.value}</div>
          <div>{compareInvestmentsData.disclaimerTextPart3.value}</div>
        </div>
      </div>
    </div>
  )
}

export default withFirebase(injectIntl(CompareInvestments))

export const query = graphql`
  fragment CompareInvestments on CockpitMainPage {
    compareInvestments {
      value {
        animation {
          value {
            publicURL
          }
        }
        buttonText {
          value
        }
        comparativeTextPart1 {
          value
        }
        comparativeTextPart2 {
          value
        }
        comparativeTextPart3 {
          value
        }
        comparativeTextPart4 {
          value
        }
        contactOptions {
          value {
            value {
              defaultText {
                value
              }
              loginOptions {
                value {
                  cockpitId
                  name {
                    value
                  }
                }
              }
            }
          }
        }
        contentText1 {
          value
        }
        contentText2 {
          value
        }
        contentText3 {
          value
        }
        description {
          value
        }
        disclaimerTextPart1 {
          value
        }
        disclaimerTextPart2 {
          value
        }
        disclaimerTextPart3 {
          value
        }
        graphEndLabel {
          value
        }
        graphStartLabel {
          value
        }
        graphTitle {
          value
        }
        mieuxPlanSelection {
          value
        }
        otherSelectionPrefix {
          value
        }
        ourSelection {
          value
        }
        rankingTextPart1 {
          value
        }
        rankingTextPart2 {
          value
        }
        rankingTextPart3 {
          value
        }
        rankingNumberSuffix {
          value
        }
        appointment {
          value
        }
        resultInDate {
          value
        }
        secondSideButtonText {
          value
        }
        secondSideTitle {
          value
        }
        dot {
          value {
            publicURL
          }
        }
        title {
          value
        }
        yearSuffix {
          value
        }
        yearSuffixPlural {
          value
        }
        yourInvestments {
          value
        }
        arrow {
          value {
            publicURL
          }
        }
        enterCodeButtonText {
          value
        }
        enterPhoneNumberText {
          value
        }
        addIcon {
          value {
            publicURL
          }
        }
        removeIcon {
          value {
            publicURL
          }
        }
      }
    }
  }
`
